<template>
  <div class="data-monitor">
    <Header />
    <div class="content">
      <router-link to="/dataMonitor/sales">
        <div class="item" style="background: rgba(202, 99, 228, 0.1);">
          <img class="icon" src="../../assets/images/dataMonitor/item1.png" />
          <div class="label">销量分析</div>
        </div>
      </router-link>

      <router-link to="/dataMonitor/production">
        <div class="item" style="background: rgba(99, 158, 246, 0.1);">
          <img class="icon" src="../../assets/images/dataMonitor/item2.png" />
          <div class="label">核心产品</div>
        </div>
      </router-link>

      <router-link to="/dataMonitor/channel">
        <div class="item" style="background: rgba(57, 183, 129, 0.1);">
          <img class="icon" src="../../assets/images/dataMonitor/item3.png" />
          <div class="label">渠道变化</div>
        </div>
      </router-link>

      <router-link to="/dataMonitor/towns">
        <div class="item" style="background: rgba(252, 150, 55, 0.1);">
          <img class="icon" src="../../assets/images/dataMonitor/item4.png" />
          <div class="label">乡镇终端</div>
        </div>
      </router-link>

      <router-link to="/dataMonitor/terminal">
        <div class="item" style="background: rgba(202, 99, 228, 0.1);">
          <img class="icon" src="../../assets/images/dataMonitor/item5.png" />
          <div class="label">终端分析</div>
        </div>
      </router-link>

      <router-link to="/dataMonitor/crops">
        <div class="item" style="background: rgba(14, 126, 183, 0.1);">
          <img class="icon" src="../../assets/images/dataMonitor/item6.png" />
          <div class="label">作物覆盖</div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import Header from '../../components/header';
export default {
  components: {
    Header,
  },
}
</script>

<style lang="scss" scoped>
.data-monitor {
  min-height: 100vh;
  background-color: #FAFBFC;
  .content {
    display: flex;
    flex-wrap: wrap;
    padding: 13px 24px;
    align-items: center;
    justify-content: space-between;
    .item {
      width: 101px;
      height: 101px;
      box-sizing: border-box;
      padding-top: 18px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 12px;
      .icon {
        width: 45px;
        height: 45px;
      }
      .label {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #2C2C2C;
        margin-top: 12px;
      }
    }
  }
}
</style>